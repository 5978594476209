import React, { useEffect, useState } from "react";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import Loader from "../Common/Loader";
import { CONFIG } from "../../Helpers/Constants/Default";
import ConfigModal from "../Common/ConfigModal";

const Config = () => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({ loader: false });
  const [modalData, setModalData] = useState({ visible: false, data: {} });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.CONFIG.DETAILS);
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      {modalData.visible && (
        <ConfigModal
          modalData={modalData}
          setModalData={setModalData}
          setVisible={setVisible}
          setData={setData}
        />
      )}
      <div className="container-fluid py-3">
        <div className="inner-form configList">
          {data?.map((val) => (
            <div key={val._id} className="item">
              <div className="item ">
                <label>{CONFIG.TYPE[val.type]}</label>
              </div>
              <div className="item mx-3">
                <div className="form-control">{`${val.value} minutes`}</div>
              </div>

              <div className="item ">
                <div className="d-flex align-items-center ">
                  <button
                    className="mx-2 btn bg-gradient-dark  d-block btn-lg mb-1"
                    type="button"
                    onClick={() => setModalData({ visible: true, data: val })}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Config;
