import React from "react";
import Axios from "../../Helpers/Universal/Axios/Axios";

const DeleteConfirmation = ({ data, setVisible, API, setData }) => {
  const hide = () => {
    setVisible((prev) => ({
      ...prev,
      deleteConformation: { data: null, visible: false },
    }));
    setData((prev) => ({
      ...prev,
      data: prev?.data?.map((val) => ({ ...val, action: false })),
    }));
  };

  const delData = async () => {
    try {
      await Axios.delete(API + "/" + data?._id);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      hide();
    }
  };

  return (
    <div className="bg_viewpopup" style={{ zIndex: "999999" }}>
      <div className="successfull delete-modal bg-white p-4 pb-0 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
        <div className="modal-header">
          <h5>Delete Confirmation</h5>
          <label className="cross-btns" onClick={hide}>
            ×
          </label>
        </div>
        <div className="modal-bdy">
          <p className="text-dark font-weight-bold border-bottom pb-3">
            {`Are you sure want to delete "${data?.userName}" data`}
          </p>
        </div>
        <div className="modal-footer">
          <button
            className="text-center btn btn-secondary text-white  "
            onClick={hide}
          >
            Cancel
          </button>
          <button
            className="text-center btn btn-primary text-white "
            onClick={delData}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
