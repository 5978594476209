import { EndPoints } from "../../Helpers/Constants/EndPoints";

import Error from "../../Components/Auth/Error";
import Login from "../../Components/Auth/Login";

import Dashboard from "../../Components/Dashboard/Dashboard";

import Agents from "../../Components/Listing/Agents";
import Supervisors from "../../Components/Listing/Supervisors";
import Managers from "../../Components/Listing/Managers";
import SubAdmins from "../../Components/Listing/SubAdmins";

import AddManager from "../../Components/Forms/Manager";
import AddAgent from "../../Components/Forms/Agent";
import AddSupervisor from "../../Components/Forms/Supervisor";
import AddSubAdmin from "../../Components/Forms/SubAdmin";

import SubAdmin from "../../Components/view/SubAdmin";
import Agent from "../../Components/view/Agent";
import Supervisor from "../../Components/view/Supervisor";
import Manager from "../../Components/view/Manager";
import SuperLogout from "../../Components/Auth/SuperLogout";
import AttendanceSlot from "../../Components/view/AttendanceSlot";
import Attendance from "../../Components/Listing/Attendance";
import AddNotification from "../../Components/Forms/Notification";
import Cms from "../../Components/Forms/CMS";
import ForgotPassword from "../../Components/Auth/ForgotPassword";
import ResetPassword from "../../Components/Auth/ResetPassword";
import Config from "../../Components/Forms/Config";

const {
  NONE,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  DASHBOARD,
  SUPER_LOGOUT,

  SUB_ADMINS,
  SUB_ADMIN,
  ADD_SUB_ADMIN,
  EDIT_SUB_ADMIN,

  AGENTS,
  AGENT,
  ADD_AGENT,
  EDIT_AGENT,
  ATTENDANCE_SLOT,

  SUPERVISORS,
  SUPERVISOR,
  ADD_SUPERVISOR,
  EDIT_SUPERVISOR,

  MANAGERS,
  MANAGER,
  ADD_MANAGER,
  EDIT_MANAGER,

  ATTENDANCE,

  NOTIFICATION,

  CONFIG,

  CMS,

  UNKNOWN,
} = EndPoints;

export const routes = () => [
  { path: LOGIN, element: Login },
  { path: FORGOT_PASSWORD, element: ForgotPassword },
  { path: RESET_PASSWORD, element: ResetPassword },
  {
    path: NONE,
    element: Dashboard,
    pvtRoute: true,
  },
  {
    path: DASHBOARD,
    element: Dashboard,
    pvtRoute: true,
  },

  {
    path: SUB_ADMINS,
    element: SubAdmins,
    pvtRoute: true,
  },
  {
    path: SUB_ADMIN,
    element: SubAdmin,
    pvtRoute: true,
  },
  {
    path: ADD_SUB_ADMIN,
    element: AddSubAdmin,
    pvtRoute: true,
  },
  {
    path: EDIT_SUB_ADMIN,
    element: AddSubAdmin,
    pvtRoute: true,
  },

  {
    path: AGENTS,
    element: Agents,
    pvtRoute: true,
  },
  {
    path: AGENT,
    element: Agent,
    pvtRoute: true,
  },
  {
    path: AGENTS,
    element: Agents,
    pvtRoute: true,
  },
  {
    path: ADD_AGENT,
    element: AddAgent,
    pvtRoute: true,
  },
  {
    path: EDIT_AGENT,
    element: AddAgent,
    pvtRoute: true,
  },
  {
    path: ATTENDANCE_SLOT,
    element: AttendanceSlot,
    pvtRoute: true,
  },

  {
    path: SUPERVISORS,
    element: Supervisors,
    pvtRoute: true,
  },
  {
    path: SUPERVISOR,
    element: Supervisor,
    pvtRoute: true,
  },
  {
    path: ADD_SUPERVISOR,
    element: AddSupervisor,
    pvtRoute: true,
  },
  {
    path: EDIT_SUPERVISOR,
    element: AddSupervisor,
    pvtRoute: true,
  },

  {
    path: MANAGERS,
    element: Managers,
    pvtRoute: true,
  },
  {
    path: MANAGER,
    element: Manager,
    pvtRoute: true,
  },
  {
    path: ADD_MANAGER,
    element: AddManager,
    pvtRoute: true,
  },
  {
    path: EDIT_MANAGER,
    element: AddManager,
    pvtRoute: true,
  },

  {
    path: ATTENDANCE,
    element: Attendance,
    pvtRoute: true,
  },

  {
    path: NOTIFICATION,
    element: AddNotification,
    pvtRoute: true,
  },

  {
    path: CONFIG,
    element: Config,
    pvtRoute: true,
  },

  {
    path: CMS,
    element: Cms,
    pvtRoute: true,
  },

  {
    path: SUPER_LOGOUT,
    element: SuperLogout,
    pvtRoute: true,
  },
  {
    path: UNKNOWN,
    element: Error,
    pvtRoute: true,
  },
];
