import { EndPoints } from "../../Helpers/Constants/EndPoints";

const {
  NONE,
  DASHBOARD,
  SUPER_LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,

  SUB_ADMINS,
  SUB_ADMIN,
  ADD_SUB_ADMIN,
  EDIT_SUB_ADMIN,
  ATTENDANCE_SLOT,

  AGENTS,
  AGENT,
  ADD_AGENT,
  EDIT_AGENT,

  SUPERVISORS,
  SUPERVISOR,
  ADD_SUPERVISOR,
  EDIT_SUPERVISOR,

  MANAGERS,
  MANAGER,
  ADD_MANAGER,
  EDIT_MANAGER,

  ATTENDANCE,

  NOTIFICATION,

  CONFIG,

  CMS,
} = EndPoints;

export const HeaderContent = {
  [NONE]: "Dashboard",
  [DASHBOARD]: "Dashboard",
  [SUPER_LOGOUT]: "Super Logout",
  [FORGOT_PASSWORD]: "Forgot Password",
  [RESET_PASSWORD]: "Reset Password",

  [SUB_ADMINS]: "Sub Admins",
  [SUB_ADMIN]: "Sub Admin",
  [ADD_SUB_ADMIN]: "Create Sub-Admin",
  [EDIT_SUB_ADMIN]: "Update Sub-Admin",

  [AGENTS]: "Agents",
  [AGENT]: "Agent",
  [ADD_AGENT]: "Create Agent",
  [EDIT_AGENT]: "Update Agent",
  [ATTENDANCE_SLOT]: "Attendance Slots",

  [SUPERVISORS]: "Supervisors",
  [SUPERVISOR]: "Supervisor",
  [ADD_SUPERVISOR]: "Create Supervisor",
  [EDIT_SUPERVISOR]: "Update Supervisor",

  [MANAGERS]: "Managers",
  [MANAGER]: "Manager",
  [ADD_MANAGER]: "Create Manager",
  [EDIT_MANAGER]: "Update Manager",

  [ATTENDANCE]: "Attendance",

  [NOTIFICATION]: "Send Push Notification",

  [CONFIG]: "Config",

  [CMS]: "Content Management",
};
