import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { AgentSchema } from "../../Helpers/Constants/Schemas";
import { AgentFields } from "../../Helpers/Constants/InitialValues";

import { EndPoints } from "../../Helpers/Constants/EndPoints";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  CREATE,
  UPDATE,
  USER_IDS,
  USER_TYPE,
} from "../../Helpers/Constants/Default";
import { UpdateParam } from "../../Helpers/Universal/Function/common";

const randomSerialNumber = String(Math.floor(Math.random() * 999) + 1).padStart(
  3,
  "0"
);

const Agent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpdate] = useState(state ? true : false);
  const [addFetchPincode, setAddFetchPincode] = useState(true);
  const [initialValues, setInitialValue] = useState(
    isUpdate ? state : AgentFields.required
  );
  const [dropdown, setDropdown] = useState({
    supervisors: [],
    countries: [],
    states: [],
    cities: [],
  });

  const handleSubmit = async (data) => {
    try {
      data.address.stateCode = initialValues.address.stateCode;
      const { status } = await Axios.post(API.AGENT.CREATE, data);
      if (status) navigate(EndPoints.AGENTS);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getDropdowns = async () => {
    try {
      const { data, status } = await Axios.get(API.DROPDOWN.USER, {
        accountType: USER_TYPE.SUPERVISOR,
      });

      if (status) setDropdown((prev) => ({ ...prev, supervisors: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const fetchCountries = async () => {
    try {
      const { data, status } = await Axios.get(API.CSC.COUNTRIES);
      if (status) setDropdown((prev) => ({ ...prev, countries: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const fetchStates = async (parentId) => {
    try {
      const { data, status } = await Axios.get(API.CSC.STATES, { parentId });
      if (status) setDropdown((prev) => ({ ...prev, states: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const fetchCities = async (e, setFieldValue, values) => {
    try {
      const parentId =
          e.target?.options[e.target?.selectedIndex]?.dataset?.parentid,
        stateCode =
          e.target?.options[e.target?.selectedIndex]?.dataset?.statecode;

      setInitialValue((prev) => ({
        ...prev,
        address: { ...prev.address, stateCode },
      }));

      if (!isUpdate) {
        const userName = generateUserId(values.name, stateCode);
        setFieldValue("userName", userName);
      }

      const { data, status } = await Axios.get(API.CSC.CITIES, { parentId });
      if (status) setDropdown((prev) => ({ ...prev, cities: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleChangeAddress = async (pincode, setFieldValue, values) => {
    try {
      let stateCode = "";

      setFieldValue("address.pincode", pincode);

      if (pincode.length === 6) {
        const data = await Axios.get(API.COMMON.ADDRESS_API, {
          pincode,
        });

        if (data?.status) {
          setAddFetchPincode(true);

          setFieldValue("address.country", data?.data?.country || "");
          setFieldValue("address.state", data?.data?.state || "");
          setFieldValue("address.city", data?.data?.city || "");
          setFieldValue("address.address", data?.data?.formatted_address || "");

          stateCode = data?.data?.state_code || "";

          setInitialValue((prev) => ({
            ...prev,
            address: { ...prev.address, stateCode },
          }));
        } else {
          setAddFetchPincode(false);

          dropdown.countries.length || fetchCountries();

          setFieldValue("address.country", "");
          setFieldValue("address.state", "");
          setFieldValue("address.city", "");
          setFieldValue("address.address", "");
        }

        if (!isUpdate) {
          const userName = generateUserId(values.name, stateCode);
          setFieldValue("userName", userName);
        }
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleChangeUserId = async (name, setFieldValue) => {
    try {
      setFieldValue("name", name);
      const userName = generateUserId(name, initialValues.address?.stateCode);
      setFieldValue("userName", userName);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const generateUserId = (name, stateCode) => {
    let userName = name?.toLowerCase()?.replace(/\s/g, "");

    if (userName) {
      userName = `${userName}${randomSerialNumber}`;
      userName = stateCode
        ? `${userName}.${stateCode}.${USER_IDS.AGENT}`
        : userName;
    }

    return userName;
  };

  const handleUpdateDocument = async (data, setSubmitting) => {
    try {
      const params = await UpdateParam(AgentFields, data, ["userName", "name"]);
      const { status } = await Axios.patch(API.AGENT.UPDATE, {
        agentId: initialValues._id,
        ...params,
      });

      if (status) navigate(EndPoints.AGENTS);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AgentSchema}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        isUpdate
          ? handleUpdateDocument(values, setSubmitting)
          : handleSubmit(values);
        // resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleChange,
      }) => (
        <Form className="page-from">
          <div className="inner-form">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Name</label>
                <Field
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={(e) =>
                    handleChangeUserId(e?.target?.value, setFieldValue)
                  }
                  disabled={isUpdate}
                />
                {errors.name && touched.name ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div>

              <div className="col-md-6 mb-3">
                <label>Email</label>
                <Field className="form-control" type="email" name="email" />
                {errors.email && touched.email ? (
                  <div className="error">{errors.email}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Password</label>
                <Field className="form-control" type="text" name="password" />
                {errors.password && touched.password ? (
                  <div className="error">{errors.password}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Supervisor</label>
                <Field className="form-control" name="parentId" as="select">
                  <option value="">Click to Select</option>
                  {dropdown.supervisors?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </Field>
                {errors.parentId && touched.parentId ? (
                  <div className="error">{errors.parentId}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>User Id</label>
                <Field
                  className="form-control"
                  type="text"
                  name="userName"
                  disabled={true}
                />
                {errors.userName && touched.userName ? (
                  <div className="error">{errors.userName}</div>
                ) : null}
              </div>

              <div className="col-md-12 mb-3">
                <h3>Address</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="address.pincode">Pincode</label>
                      <Field
                        type="number"
                        className="form-control"
                        name="address.pincode"
                        onChange={(e) =>
                          handleChangeAddress(
                            e?.target?.value,
                            setFieldValue,
                            values
                          )
                        }
                      />
                      {errors.address?.pincode && touched.address?.pincode ? (
                        <div className="error">{errors.address?.pincode}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="address.address">Address</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="address.address"
                      />
                      {errors.address?.address && touched.address?.address ? (
                        <div className="error">{errors.address?.address}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="address.country">Country</label>

                      {addFetchPincode ? (
                        <Field
                          className="form-control"
                          type="text"
                          name="address.country"
                          disabled={true}
                        />
                      ) : (
                        <Field
                          className="form-control"
                          as="select"
                          name="address.country"
                          onChange={(e) => {
                            handleChange(e);
                            fetchStates(
                              e.target?.options[e.target?.selectedIndex]
                                ?.dataset?.parentid,
                              setFieldValue,
                              values
                            );
                          }}
                        >
                          <option value="">Click to Select</option>
                          {dropdown.countries?.map((country) => (
                            <option
                              key={country._id}
                              value={country.name}
                              data-parentid={country._id}
                            >
                              {country.name}
                            </option>
                          ))}
                        </Field>
                      )}

                      {errors.address?.country && touched.address?.country ? (
                        <div className="error">{errors.address?.country}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="address.state">State</label>

                      {addFetchPincode ? (
                        <Field
                          type="text"
                          className="form-control"
                          name="address.state"
                          disabled={true}
                        />
                      ) : (
                        <Field
                          className="form-control"
                          as="select"
                          name="address.state"
                          onChange={(e) => {
                            handleChange(e);
                            fetchCities(e, setFieldValue, values);
                          }}
                        >
                          <option value="">Click to Select</option>
                          {dropdown.states?.map((state) => (
                            <option
                              key={state._id}
                              value={state.name}
                              data-parentid={state._id}
                              data-statecode={state.code}
                            >
                              {state.name}
                            </option>
                          ))}
                        </Field>
                      )}

                      {errors.address?.state && touched.address?.state ? (
                        <div className="error">{errors.address?.state}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="address.city">City</label>

                      {addFetchPincode ? (
                        <Field
                          type="text"
                          className="form-control"
                          name="address.city"
                          disabled={true}
                        />
                      ) : (
                        <Field
                          className="form-control"
                          as="select"
                          name="address.city"
                        >
                          <option value="">Click to Select</option>
                          {dropdown.cities?.map((city) => (
                            <option key={city._id} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </Field>
                      )}

                      {errors.address?.city && touched.address?.city ? (
                        <div className="error">{errors.address?.city}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-3 mt-3">
                <button
                  disabled={isSubmitting}
                  className="btn bg-gradient-dark d-block btn-lg mb-1"
                  type="submit"
                >
                  {isUpdate ? UPDATE : CREATE}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Agent;
