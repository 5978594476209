import axios from "axios";
import { toast } from "react-toastify";

import { API_URL } from "../../../config/config";

const AXIOS = axios.create({ baseURL: API_URL });

AXIOS.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) config.headers["authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const Axios = {
  SUCCESS: async (data) => {
    // console.log("SUCCESS", data);
    if (data?.status) {
      toast.success(data?.message);
      return { status: true, data: data?.data };
    }
  },

  FAILURE: async (data) => {
    // console.log("FAILURE", data);

    if (data?.statusCode === 401 && data?.message === "Unauthorized") {
      localStorage.clear();
      data.message = "Token Expired - Re-login";
    }

    toast.error(data?.message);
    return { status: false, data: data?.data || null };
  },

  get: async (endPoint, query) => {
    try {
      const res = await AXIOS.get(endPoint, { params: query });
      return Axios.SUCCESS(res.data);
    } catch (error) {
      Axios.FAILURE(error?.response?.data);
    }
  },

  patch: async (endPoint, data) => {
    try {
      const res = await AXIOS.patch(endPoint, data);
      return Axios.SUCCESS(res?.data);
    } catch (error) {
      Axios.FAILURE(error?.response?.data);
    }
  },

  put: async (endPoint, data) => {
    try {
      const res = await AXIOS.put(endPoint, data);
      return Axios.SUCCESS(res?.data);
    } catch (error) {
      Axios.FAILURE(error?.response?.data);
    }
  },

  post: async (endPoint, data) => {
    try {
      const res = await AXIOS.post(endPoint, data);
      return Axios.SUCCESS(res?.data);
    } catch (error) {
      Axios.FAILURE(error?.response?.data);
    }
  },

  delete: async (endPoint, data) => {
    try {
      const res = await AXIOS.delete(endPoint, data);
      return Axios.SUCCESS(res?.data);
    } catch (error) {
      Axios.FAILURE(error?.response?.data);
    }
  },
};

export default Axios;
